<template>
  <div class="mentor-container">
    <JoymewHeader />
    <div class="ec-top">
      <img src="@/assets/image/wenzi.png" alt="嗨喵悦动,嗨喵互动,牵手著名主持人" />
      <span>牵手著名婚礼主持人，让活动更精彩</span>
    </div>
    <div class="ec-center">
      <div class="ec-c-left">
        <div class="avatar">
          <img :src="recommendMentor.src" style="height: 100%;width: 100%;" alt="嗨喵悦动,嗨喵互动,月度推荐导师">
          <div class="desc">
            <span>月度推荐导师</span>
          </div>
        </div>
      </div>
      <div class="ec-c-right">
        <h2>{{ recommendMentor.name }}</h2>
        <div class="point">
          <span class="tag" v-for="i in recommendMentor.tags" :key="i">{{ i }}</span>
          <div>
            <img src="@/assets/image/aixin.png" alt="嗨喵悦动,嗨喵互动,嗨喵导师" />
            <span>{{ recommendMentor.exp }}</span>
          </div>
        </div>
        <div class="mentor-type">
          导师身份：{{ recommendMentor.type[0] }}    {{ recommendMentor.type[1] }}
        </div>
        <div class="mentor-desc">
          导师介绍：{{ recommendMentor.desc }}
        </div>
        <div class="mentor-likes">
          <img src="@/assets/image/dianzan.png" alt="嗨喵悦动,嗨喵互动,图标">
          <span>{{ recommendMentor.dianzan }}人点赞</span>
          <img src="@/assets/image/aixin.png" alt="嗨喵悦动,嗨喵互动,图标">
          <span>{{ recommendMentor.aixin }}人喜欢</span>
        </div>
        <div class="mentor-btns">
          <span @click="showQrcode = true">立即咨询</span>
          <span @click="showQrcode = true">更多导师</span>
        </div>
      </div>
    </div>

    <div class="ec-bottom">
      <h2 class="title">导师团队</h2>
      <div class="list">
        <div class="item" v-for="i in list" :key="i.name">
          <div class="left">
            <img :src="i.src" style="height: 100%;width: 100%;object-fit: cover;" alt="嗨喵悦动,嗨喵互动,图标">
          </div>
          <div class="right">
            <div class="title">
              <span>{{ i.name }}</span>
              <img src="@/assets/image/aixin.png" alt="嗨喵悦动,嗨喵互动,图标">
              <span>{{ i.exp }}</span>
            </div>
            <div class="tags">
              <span v-for="$ in i.tags" :key="$">{{ $ }}</span>
            </div>
            <div class="type">
              导师身份：{{ i.type[0] }}    {{ i.type[1] }}
            </div>
            <div class="desc">
              导师介绍：{{ i.desc }}
            </div>
            <div class="likes">
              <img src="@/assets/image/dianzan.png" alt="嗨喵悦动,嗨喵互动,图标">
              <span>{{ i.dianzan }}人点赞</span>
              <img src="@/assets/image/aixin.png" alt="嗨喵悦动,嗨喵互动,图标">
              <span>{{ i.aixin }}人喜欢</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ec-footer">
      <my-footer style="background-color: transparent;" />
    </div>

    <div @click="showQrcode = false" v-show="showQrcode" class="qrcode-popup">
      <div @click.stop class="content">
        <img src="@/assets/image/qrcode.png" alt="嗨喵悦动,嗨喵互动,客服微信">
        <span>添加客服微信，咨询详情</span>
      </div>
    </div>
  </div>
</template>

<script>
import JoymewHeader from "@/components/layout/joymewHeader.vue";
import fcz from '@/assets/image/fcz.jpg';
import hc from '@/assets/image/hc.jpg';
import jn from '@/assets/image/jn.jpg';
import jh from '@/assets/image/jh.jpg';
import ly from '@/assets/image/ly.jpg';
import lc from '@/assets/image/lc.jpg';
import lr from '@/assets/image/lr.jpg';
import lz from '@/assets/image/lz.jpg';
import pp from '@/assets/image/pp.jpg';
import sj from '@/assets/image/sj.jpg';
import xb from '@/assets/image/xb.jpg';
import xm from '@/assets/image/xm.jpg';

import myFooter from "@/components/myFooter.vue";

export default {
  name: "mentor",
  components: {
    JoymewHeader,
    myFooter,
  },
  data: () => ({
    showQrcode: false,
    list: [
  {
    name: "佳男",
    tags: ["西式婚礼", "千场主持经验"],
    exp: "6年婚礼主持经验",
    type: ["嗨喵产品特邀顾问", "嗨喵高级讲师"],
    desc: "一个真诚，温暖，阳光的天秤座主持人。2018年正式进入婚礼主持行业，入行6年，近千场主持经验。擅长西式婚礼，年会，酒会，订货会等多种场合的主持。主持风格仪式没有刻意煽情，轻松，自然，定制类讲述型。具有丰富的后场互动经验，才艺展示（唱歌&跳舞），互动游戏参与，大小朋友俱欢乐，氛围热闹不俗套。",
    src: jn,
    dianzan:3874,
    aixin:865,
  },
  {
    name: "金宏",
    tags: ["电视台主持人", "综艺感"],
    exp: "10年婚礼主持经验",
    type: ["嗨喵产品特邀顾问", "嗨喵高级讲师"],
    desc: "重庆电视台外景主持人，一线家居品牌指定主持人，深耕全领域主持十年。主持风格轻松愉快松弛感，同时兼具综艺感，与南拳妈妈、黄雅莉、水木年华，崔子格、女团AKB48等多位明星有过合作，是一位名副其实的明星主持人。"
    ,src: jh,
    dianzan:3280,
    aixin:561,
  },
  {
    name: "盼盼",
    tags: ["明星主持人", "婚礼风尚冠军"],
    exp: "10年婚礼主持经验",
    type: ["嗨喵产品特邀顾问", "嗨喵高级讲师"],
    desc: "中国著名婚礼主持团队-朗艺主持人团队创始成员，极卷主义课程创办人，同时获得过亚洲婚礼风尚主持人创造营冠军，主持风格轻松幽默，氛围活泼，具有丰富的婚礼和各类商演活动主持经验，盼盼老师同时还是诸多网红艺人、国乒冠军、亚洲舞王等名流明星的特邀婚礼主持人。"
    ,src: pp,
    dianzan:1563,
    aixin:210,
  },
  {
    name: "李照",
    tags: ["播音员主持人", "中式婚礼", "口才演讲教育"],
    exp: "15年婚礼主持经验",
    type: ["嗨喵产品特邀顾问", "嗨喵高级讲师"],
    desc: "毕业于中国传媒大学播音主持专业，国家广电总局播音主持证持有者，电台资深配音员主持人，同时从事口才演讲教育十余年，擅长中西式户外等各类婚礼主持，明言国风婚礼全国城市发起人，主持风格儒雅大气，真诚共情，极具感染力，是一位德才兼备的专业婚礼主持人。"
    ,src: lz,
    dianzan:7510,
    aixin:2679,
  },
  {
    name: "何超",
    tags: ["脱口秀风格", "西式婚礼"],
    exp: "20年婚礼主持经验",
    type: ["嗨喵产品特邀顾问", "嗨喵高级讲师"],
    desc: "传奇（中国）金十联盟创始人，内蒙古婚庆礼仪行业协会副秘书长，全国名优100项品牌地方推广签约主持人，星光大道地区海选现场主持人，多次荣获各类主持金奖，具有极为丰富的婚礼、商演、比赛、演出等各类活动主持经验，从事主持、文化传媒教育工作多年，特别擅长综艺娱乐性互动，主持风格诙谐幽默，轻松活泼，应变能力超强，是一位极具个性的优秀婚礼主持人。"
    ,src: hc,
    dianzan:2418,
    aixin:350,
  },
  {
    name: "非驰中",
    tags: ["司仪大赛冠军", "自媒体百万博主"],
    exp: "11年婚礼主持经验",
    type: ["嗨喵产品特邀顾问", "嗨喵高级讲师"],
    desc: "【就是说】语言艺术中心创始人，【亚洲婚礼风尚】签约主持人，浙江横影职业影视学院特聘教师，中国好声音浙江武义赛区导师，司仪大赛冠军得主，具有丰富的各类活动主持经验，主持风格轻松幽默，具有亲和力，是一位人气极高，才艺俱佳的魅力主持人。"
    ,src: fcz,
    dianzan:1068,
    aixin:173,
  },
  {
    name: "申喆",
    tags: ["资深婚礼人", "婚礼策划师"],
    exp: "19年婚礼主持经验",
    type: ["嗨喵产品特邀顾问", "嗨喵高级讲师"],
    desc: "资深婚礼人，婚礼策划师，主持过3000场婚礼，3600场生日，2600场庆典，具有极为丰富的婚礼、商演活动主持经验，以大气唯美，细腻走心，愉悦共情的主持风格获得了无数新人的一致好评，对主持行业的满腔热情，对职业发展的无限专注，用心做好每一场婚礼，他是一位具有极高职业素养，传递幸福、快乐与爱的优秀主持人。"
    ,src: sj,
    dianzan:4847,
    aixin:1805,
  },
  {
    name: "李瑞",
    tags: ["十大明星司仪", "婚礼宝典编者"],
    exp: "16年婚礼主持经验",
    type: ["嗨喵产品特邀顾问", "嗨喵高级讲师"],
    desc: "「听婚李」品牌创始人，《婚礼主持宝典》系列丛书编者，福州520婚礼梦想节“十大明星司仪”，“第七届全国婚礼主持人大赛”总决赛银奖得主，擅长为每一位新人打造一场独一无二的梦想婚礼，其细腻温婉，自然走心的主持风格，不仅深受新人的喜爱，更赢得了诸多同行肯定与赞扬，他是一位有温度的婚礼主持人。"
    ,src: lr,
    dianzan:5786,
    aixin:2579,
  },
  {
    name: "李闯",
    tags: ["主持团队主理人", "婚庆协会副会长"],
    exp: "16年婚礼主持经验",
    type: ["嗨喵产品特邀顾问", "嗨喵高级讲师"],
    desc: "译声主持团队主理人，同时也是濮阳市婚庆协会副会长，具有多年的婚礼活动主持经验，主持风格偏向简约自然，大气温婉，在当地享有极高的人气，他说，将婚礼主持作为终身热爱的事业， 是他最正确的选择。"
    ,src: lc,
    dianzan:1980,
    aixin:251,
  },
  {
    name: "礼言",
    tags: ["中式婚礼", "国风系"],
    exp: "15年婚礼主持经验",
    type: ["嗨喵产品特邀顾问", "嗨喵高级讲师"],
    desc: "一位声誉卓著的婚礼主持人，多年的从业经验让他能游刃有余地驾驭任何活动场合，主持经典西式婚礼他走心不煽情，简约不简单；他尤其擅长主持中式婚礼，精通全朝代国风婚礼，向阳而生，带来温暖与爱，他是江浙沪皖最受欢迎的中式主持人。"
    ,src: ly,
    dianzan:2970,
    aixin:208,
  },
  {
    name: "小宝",
    tags: ["西式婚礼", "千场活动"],
    exp: "10年婚礼主持经验",
    type: ["嗨喵产品特邀顾问", "嗨喵高级讲师"],
    desc: "他是来自江苏镇江的一位优秀婚礼主持人，主持风格幽默风趣，大气沉稳，超强的控场能力总能让他搏得满堂喝彩，让所有活动现场来宾体验一场场完美婚礼，给每位新人带来终身难忘的美好回忆。"
    ,src: xb,
    dianzan:1670,
    aixin:232,
  },
  {
    name: "小明",
    tags: ["明星婚礼主持人", "独具风格"],
    exp: "15年婚礼主持经验",
    type: ["嗨喵产品特邀顾问", "嗨喵高级讲师"],
    desc: "毕业于中央民族大学影视表演系，唱歌、表演、跳舞、钢琴演奏都是他的强项，他将这些都融入了他主持的婚礼中，为每一对新人打造一场专属婚礼，帮助新人完成他们的梦想！他是人气王，他是明星婚礼主持人，主持的了婚礼、宝宝宴，上的了娱乐综艺、玩得转商业活动，他是全能型的主持人。新人们评价他“可咸可甜，能文能舞，节奏把控的大师，嘻笑开怀的活宝，一生必选他一次！”。"
    ,src: xm,
    dianzan:2970,
    aixin:398,
  }
]
  }),

  computed: {
    recommendMentor() {
      return this.list.find(item => item.name === '李照')
    }
  }
};
</script>

<style lang="less" scoped>
.qrcode-popup {
  // backdrop-filter: blur(7.5px);
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  // background-color: red;
  z-index: 100;
  justify-content: center;
  align-items: center;

  .content {
    transform: scale(.8);
    filter: drop-shadow(0 0 7.5px rgba(0,0,0,0.25));
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 32.0025px;
    padding: 33px;
    background-color: #fff;
    border-radius: 12px;
    img {
      width: 432.99750000000006px;
      height: 431.00249999999994px;

    }
  }
}
::v-deep footer  {

  height: 100% !important;
  .copyRight {
    margin-bottom: 60px;
  }
  .container {
    padding-top: 90px !important;
    display: flex;
    flex-direction: column;
    height: 100% !important;
  }
  .imgBox {
    margin-top: auto !important;
  }
  .info {
    background-color: transparent !important;
  }
  .tit {
    color: white !important;
  }
   .item {
    color: white !important;
    a {
      color: white !important;
    }
  }

}
.mentor-container {
  background-color: #1a0e0e;
  .ec-top {
    height: 633.75px;
    background-image: url(~@/assets/image/beijing.png);
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 1042.5px;
      height: 96px;
      margin-top: 156px;
    }

    span {
      font-size: 27.9975px;
      margin-top: 35.25px;
      text-align: center;
      color: white;
    }
  }

  .ec-center {
    margin-top: -191.57999999999998px;
    display: flex;
    justify-content: center;
    padding: 0 var(--mentor-padding);
    .ec-c-left {
      margin-right: 39.9975px;
      width: 0;
      flex: 0 0 399.99750000000006px;
      .dots {
        display: flex;
        margin-top: 19.665px;
        justify-content: center;
        span {
        border-radius: 50%;
        margin-right: 12px;
          width: 12px;
          height: 12px;
          background-color: rgba(255,255,255,.3);

          &.active {
            background-color: rgba(255,255,255,1);
          }
        }
      }
      .avatar {
        height: 533.3325px;
        background-color: #d9d9d9;
        position: relative;
        overflow: hidden;
        .desc {
          font-size: 18px;
          position: absolute;
          background-color: #ffbf75;
          color: white;
          transform: translate(-50%, -50%) rotate(-45deg);
          top: 10%;
          width: 225px;
          left: 13.33%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .ec-c-right {
      display: flex;
      flex-direction: column;
      padding-top: 25.2525px;
      width: 0;
      flex: 1;
      // background-color: rebeccapurple;

      h2 {
        color: white;
        font-size: 56.0025px;
      }

      .point {
        margin-top: 20.0025px;
        display: flex;
        align-items: center;
        .tag {
        border-radius: 3.9975px;
        border: 0.9975px solid #ffcc4f;
        font-size: 14.002500000000001px;
        color: #ffcc4f;
        padding: 6px 15.997499999999999px;
        margin-right: 15.997499999999999px;
       }
       div {
        display: flex;
        align-items: center;
        color: white;
        font-size: 15.997499999999999px;
        img {
          width: 24px;
          height: 24px;
          margin-right: 8.0025px;
        }
       }
      }

      .mentor-type {
        color: white;
        font-size: 24px;
        margin: 26.25px 0 34.5px 0;
      }

      .mentor-desc {
        color: white;
        font-size: 24px;
      }

      .mentor-likes {
        display: flex;
        align-items: center;
        color: white;
        margin: 57.9975px 0 33px 0;
        img{
          width: 20.0025px;
          height: 20.0025px;
          margin-right: 8.0025px;

        }
        span {
          margin-right: 42px;
        }
      }

      .mentor-btns {
        padding: 8.0025px 0;
        span {
          color: white;
          background-color: #008DFF;
          padding: 8.0025px 24px;
          margin-right: 51.5025px;
          border-radius: 3.9975px;
        }
      }
    }
  }


  .ec-bottom {
    padding: 0 var(--mentor-padding);

    >.title {
      color: white;
      font-size: 39.9975px;
      margin-top: 99.9975px;
      margin-bottom: 39.9975px;
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        flex: 0 1 calc(615px);
        padding: 20.0025px;
        height: 360px;
        background-color: white;
        margin-bottom: 20.0025px;
        display: flex;
        .left {
          margin-right: 15.997499999999999px;
          background-color: #D9D9D9;
          width: 0;
          flex: 0 0 232.5px;
          height: 100%;
        }

        .right {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          .title {
            color: black;
            display: flex;
            align-items: center;

            span:first-child {
              font-size: 32.0025px;
              font-weight: bold;

            }
            img:nth-child(2) {
              width: 20.0025px;
              height: 17.16px;
              margin: 0 9.9975px 0 27.9975px;
            }

            span:last-child {
              color: rgba(0,0,0,.4);
            }
          }

          .tags {
            display: flex;
            margin: 12px 0;
            span {
              margin-right: 9.9975px;
              padding: 6px 15.997499999999999px;
              color: #ffcc4f;
              background-color: rgba(255,204,79,0.1);
              border: 0.9975px solid #ffcc4f;
              border-radius: 3.9975px;
            }
          }

          .type {
            font-size: 14.002500000000001px;
            margin-bottom: 12px;
          }

          .desc {
            font-size: 14.002500000000001px;
            display: -webkit-box;             /* 必须组合使用 */
  -webkit-box-orient: vertical;     /* 设置盒子内部子元素排列方式 */
  -webkit-line-clamp: 6;            /* 限制显示的行数 */
  overflow: hidden;                 /* 超出内容隐藏 */
  text-overflow: ellipsis;          /* 显示省略号 */
          }

          .likes {
            display: flex;
            align-items: center;
            margin: auto 0 23.25px 0;
            img {
              width: 16.5px;
              height: 14.25px;
              margin-right: 9.75px;
            }
            span{
              margin-right: 33.75px;
            }
          }

          
        }
      }
    }
  }

  .ec-footer {
    height: 660px;
    background-image: url(~@/assets/image/dibu.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>
